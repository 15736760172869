/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import _, { get } from 'lodash';
import UploadClient from '@uploadcare/upload-client';
import { H1 } from '@allthingswww/client-act-shared';
import Layout from '../components/Layout';
import { getEvents, updateEvent, fetchEvents } from '../redux/event';
import { getEventsItems } from '../redux/eventsItems';
import { getMyInfo } from '../redux/member';
import Survey from '../components/Survey';
import surveyData from '../../static/surveys/updateEvent.json';
import { dataURLtoFile } from '../lib';

const MainContainer = styled.div``;

function expandDateField(dateField = '') {
  const dateTime = dateField.split('T');
  const date = dateTime[0];
  let time = '';
  if (dateTime.length > 1) {
    time = dateTime[1].substring(0, dateTime[1].length - 3);
  }
  return [date, time];
}

function mergeDateAndTime(date, time) {
  return `${date}T${time}:00`;
}

function findIndexSurveyjs(name, survey) {
  return survey.pages[0].elements.findIndex((e) => e.name === name);
}

const EventsPage = (props) => {
  const id = props['*'];
  const dispatch = useDispatch();
  const events = useSelector(getEvents);
  const event = events.filter((e) => e._id === id)[0];
  const currentMember = useSelector(getMyInfo);
  const isAdmin = currentMember?.role?.isAdmin;
  const isSuperAdmin = currentMember?.role?.isSuperAdmin;
  const allEventItems = useSelector(getEventsItems);

  const onSubmit = async (surveyResults) => {
    const { data } = surveyResults;
    if (data.time && data.date) {
      data.date = mergeDateAndTime(data.date, data.time);
      delete data.time;
    }

    if (data.endDate && data.endTime) {
      data.endDate = mergeDateAndTime(data.endDate, data.endTime);
      delete data.endTime;
    }

    if (data.rainCheckDate && data.rainCheckTime) {
      data.rainCheckDate = mergeDateAndTime(data.rainCheckDate, data.rainCheckTime);
      delete data.rainCheckTime;
    }

    if (data.maxDateOfRegistration && data.maxDateOfRegistrationTime) {
      data.maxDateOfRegistration = mergeDateAndTime(data.maxDateOfRegistration, data.maxDateOfRegistrationTime);
      delete data.maxDateOfRegistrationTime;
    }

    const { eventItems = [] } = data;
    if (!_.isEmpty(eventItems)) {
      data.eventItems = JSON.stringify(_.map(data.eventItems,
        (item) => _.filter(allEventItems, (i) => i.name === item)).flat());
    } else {
      data.eventItems = JSON.stringify(eventItems);
    }

    // TODO: We need image editing
    // let { photoUrls = [] } = data;
    // if (!_.isEmpty(photoUrls)) {
    //   const client = new UploadClient({
    //     publicKey: get(
    //       process,
    //       'env.UPLOADCARE_PUBLIC_KEY',
    //       '5249d2e6dd73fb499ec3'
    //     ),
    //   });

    //   //console.log({ photoUrls });

    //   for (let i = 0; i < photoUrls.length; i += 1) {
    //     const { content, name } = photoUrls[i];
    //     const fileToUpload = dataURLtoFile(content, name);
    //     // eslint-disable-next-line no-await-in-loop
    //     const upload = await client.uploadFile(fileToUpload);
    //     const { cdnUrl } = upload;
    //     photoUrls[i] = cdnUrl;
    //   }
    // }

    const payload = {
      ...data,
      // photoUrls: photoUrls.toString(),
      memberFee: data.memberFee * 100,
      nonMemberFee: data.nonMemberFee * 100,
    };
    delete payload._id;

    dispatch(updateEvent({ id, data: payload }));
    window.scrollTo(0, 0);
    setTimeout(() => {
      navigate('/members/events');
      refresh();
    }, 2000);
  };

  const refresh = () => {
    dispatch(fetchEvents());
  };

  if (!event) {
    if (typeof window !== 'undefined') navigate('/members/events');
    return (<h1>Event not found</h1>);
  }

  const items = _.map(allEventItems, (value) => value.name).filter((value) => value !== null);

  surveyData.pages[0].elements[findIndexSurveyjs('eventItems', surveyData)].choices = items;

  let data = event;
  if (typeof (event.eventItems) !== 'undefined') {
    const eventItems = JSON.parse(event.eventItems);
    data = { ...event, eventItems: eventItems.map(({ name }) => name) };
  }
  const splitDate = data.date.split('T') || ['', ''];
  const splitEndDate = data?.endDate?.split('T') || ['', ''];
  const splitMaxDateOfRegistration = data?.maxDateOfRegistration?.split('T') || ['', ''];

  const finalData = {
    ...data,
    nonMemberFee: (data.nonMemberFee / 100),
    memberFee: (data.memberFee / 100),
    date: splitDate[0] || '',
    time: splitDate[1]?.substring(0, splitDate[1]?.length - 3) || '',
    endDate: splitEndDate[0] || '',
    endTime: splitEndDate[1]?.substring(0, splitEndDate[1]?.length - 3) || '',
    maxDateOfRegistration: splitMaxDateOfRegistration[0] || '',
    maxDateOfRegistrationTime: splitMaxDateOfRegistration[1]?.substring(0, splitMaxDateOfRegistration[1]?.length - 3) || '',
  };

  return (
    <Layout title="Event Edit" subtitle="">
      <MainContainer>
        {isAdmin || isSuperAdmin ? (
          <Survey
            model={surveyData}
            onSubmit={onSubmit}
            data={finalData}
          />
        ) : (
          <H1>You don't have access to this route</H1>
        )}
      </MainContainer>
    </Layout>
  );
};

export default EventsPage;
